// @ts-ignore
import { number, object, optional, string, array, any } from 'valibot';
import { imageSchema } from '../generalSchemas';

const recipeWidgetsSchema = object({
  type: string(),
  id: string(),
  visibility: object({
    show: optional(array(any())),
    hide: optional(array(any())),
  }),
  data: array(object({
    id: number(),
    type: string(),
    attributes: any(),
    text: string(),
    children: array(any()),
  })),
  items: optional(any()),
  list: string(),
  recipes: object({
    postId: number(),
    title: string(),
    link: string(),
    images: array(imageSchema),
  }),
});

export const fetchRecipesWidgetsSchema = {
  'recipes-above-header': optional(recipeWidgetsSchema),
  'recipes-below-header': optional(recipeWidgetsSchema),
  'recipes-list-sidebar': optional(recipeWidgetsSchema),
  'recipe-sidebar': optional(recipeWidgetsSchema),
  'recipes-above-comments': optional(recipeWidgetsSchema),
  'recipes-below-comments': optional(recipeWidgetsSchema),
  'recipes-below-ingredients': optional(recipeWidgetsSchema),
  'recipes-between-posts': optional(recipeWidgetsSchema),
};
