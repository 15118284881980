// @ts-ignore
import { object, literal, union, number, string, array, null_, unknown, boolean, optional, lazy, record } from 'valibot';
import { SeoIronMaidenSchema } from '../generalSchemas';

export const TContentSchema = object({
  type: string(),
  id: number(),
  parser_type: union([literal('gutenberg_parser'), literal('html_parser')]),
  text: string(),
  attributes: record(unknown()),
  children: array(lazy(() => TContentSchema)),
});

export const AuthorOrExpertSchema = object({
  integer_id: number(),
  name: string(),
  avatar: string(),
  url: string(),
  profile: string(),
  about: union([null_(), string()]),
  expertise: union([null_(), string()]),
  telegram: union([null_(), string()]),
  vkontakte: union([null_(), string()]),
  twitter: union([null_(), string()]),
});

export const TagSchema = object({
  id: number(),
  type: string(),
  title: string(),
  slug: string(),
  url: string(),
});

export const ArticleSchema = object({
  id: number(),
  title: string(),
  type: literal('health'),
  name: string(),
  url: string(),
  teaser_url: string(),
  date: number(),
  excerpt: string(),
  author: union([null_(), AuthorOrExpertSchema]),
  expert: union([null_(), AuthorOrExpertSchema]),

  // TODO: what
  categories: unknown(),
  tags: array(TagSchema),
  is_advertising: boolean(),
  is_promo: boolean(),
  // img: any[],
  // image: null,
  // default_img: any[],
  caption: string(),
  comments_enabled: boolean(),
  comments_count: number(),
  ab_titles_data: object({
    titles: string(),
    clicks: string(),
    views: string(),
  }),
  views: number(),
  ads_token: string(),
  reactions_enabled: boolean(),
  test_pixel: string(),
  custom_authors: string(),
  advert_label: string(),
  template: union([
    literal('one-column'),
    literal('two-column'),
    literal('three-column'),
    literal('card'),
    literal('promo'),
    literal('broadside'),
    literal('toc'),
  ]),
  json_ld: string(),
  amp_enabled: boolean(),
  last_modified: number(),
  parsed_content: array(TContentSchema),
});

export const BreadcrumbSchema = object({
  title: string(),
  url: string(),
});

export const PageTypeSchema = union([
  literal('post'),
  literal('page'),
  literal('health'),
  literal('recipe'),
  literal('category-health'),
]);

export const MetaIronMaidenSchema = object({
  adfox_params: object({
    lh_adfox_terms: string(),
    lh_adfox_tags: string(),
    lh_adfox_streams: string(),
    lh_adfox_noad: string(),
    lh_adfox_noadsence: optional(union([string(), number()])),
    lh_adfox_post_id: optional(string()),
    lh_adfox_pk: string(),
    lh_adfox_homepage: boolean(),
  }),
  main_color: union([null_(), string()]),
  jumper_scroller: union([null_(), object({
    images: union(
      [
        null_(),
        object({
          desktop: string(),
          tablet: string(),
          mobile: string(),
        }),
      ]),
    name: union([null_(), string()]),
    pitch: union([null_(), string()]),
    url: union([null_(), string()]),
    isActive: union([null_(), boolean()]),
  })]),
  lh_first_publish_date: string(),
  ads_source: object({
    url: union([null_(), string()]),
    text: union([null_(), string()]),
  }),
  advert: object({
    first_test_pixel: union([null_(), string()]),
    second_test_pixel: union([null_(), string()]),
  }),
  cover_source: object({
    url: union([null_(), string()]),
    text: union([null_(), string()]),
  }),
  source: object({
    url: union([null_(), string()]),
    text: union([null_(), string()]),
  }),
  promo: object({
    bgColor: union([null_(), string()]),
    url: union([null_(), string()]),
    images: union([object({
      topDesktop: union([null_(), string()]),
      topMobile: union([null_(), string()]),
      bottomDesktop: union([null_(), string()]),
      bottomMobile: union([null_(), string()]),
    }), null_()]),
    first_test_pixel: union([null_(), string()]),
    second_test_pixel: union([null_(), string()]),
  }),
  health_legacy: boolean(),
  custom_authors: string(),
  breadcrumb: array(object({ title: string(), url: string() })),
});

export const CategorySchema = object({
  id: number(),
  type: PageTypeSchema,
  title: string(),
  slug: string(),
  url: string(),
  parsed_description: array(TContentSchema),
  // color: union([null_(), string()]),
  icon: union([null_(), string()]),
  image: union([null_(), string()]),
  // og_image: union([null_(), string()]),
  parent: union([null_(), lazy(() => CategorySchema)]),
});

export const FetchArticlesSchema = object({
  items: array(ArticleSchema),
  meta: MetaIronMaidenSchema,
  seo: SeoIronMaidenSchema,
});

export const FetchByTagSchema = object({
  item: optional(CategorySchema),
  items: optional(array(ArticleSchema)),
  meta: optional(MetaIronMaidenSchema),
  seo: optional(SeoIronMaidenSchema),
});

export const MetaSchema = object({
  is_user_logged_in: boolean(),
  total_items: number(),
  total_pages: number(),
  current_page: number(),
  limit: number(),
  offset: number(),
  breadcrumb: optional(array(BreadcrumbSchema)),
});

export const fetchCategorySchema = object({
  item: optional(CategorySchema),
  items: optional(array(ArticleSchema)),
  meta: optional(MetaSchema),
  seo: optional(SeoIronMaidenSchema),
});

export const FetchHomeSchema = object({
  meta: MetaSchema,
  seo: SeoIronMaidenSchema,
  items: array(ArticleSchema),
});

export const MenuItemSchema = object({
  id: number(),
  title: string(),
  link: string(),
  testPixel: string(),
  backgroundColor: string(),
  textColor: string(),
  icon: string(),
});

export const MenuCategorySchema = object({
  type: string(),
  title: union([string(), null_()]),
  items: array(MenuItemSchema),
});

export const FetchMenusSchema = array(MenuCategorySchema);

export const FetchSinglePostSchema = object({
  item: ArticleSchema,
  meta: MetaIronMaidenSchema,
  seo: SeoIronMaidenSchema,
});

export const FetchTypeBySlugSchema = object({
  id: number(),
  type: PageTypeSchema,
  slug: string(),
  object: string(),
  url: string(),
});
