// @ts-ignore
import { any, array, boolean, nullish, number, object, string } from 'valibot';

export const reactionsItem = object({
  post_id: number(),
  post_slug: string(),
  post_type: string(),
  user_state: nullish(string()),
  updated_at: string(),
  created_at: string(),
  post_date: string(),
  thumbs_up_count: number(),
  thumbs_up: array(number()),
  thumbs_down_count: number(),
  thumbs_down: array(number()),
  on_fire_count: number(),
  on_fire: array(number()),
  angry_count: number(),
  angry: array(number()),
  heart_count: number(),
  heart: array(number()),
});

export const fetchReactionsSchema = {
  data: array(reactionsItem),
  meta: array(any()),
  msg: string(),
  success: boolean(),
};

export const updateReactionsSchema = {
  data: array(reactionsItem),
  meta: object({ last_modified: number() }),
  msg: string(),
  success: boolean(),
};

export const updateLikeSchema = {
  data: array(reactionsItem),
  meta: object({ last_modified: number() }),
  msg: string(),
  success: boolean(),
};

export const updateDislikeSchema = {
  data: array(reactionsItem),
  meta: object({ last_modified: number() }),
  msg: string(),
  success: boolean(),
};
