// @ts-ignore
import { any, array, number, object, optional, string } from 'valibot';

export const widgetsSchema = object({
  type: string(),
  id: string(),
  visibility: object({
    show: optional(array(any())),
    hide: optional(array(any())),
  }),
  data: array(object({
    id: number(),
    type: string(),
    attributes: any(),
    text: string(),
    children: array(any()),
  })),
  items: optional(any()),
});

export const fetchWidgetsSchema = {
  'twenty-sidebar': optional(widgetsSchema),
  'twenty-single-left': optional(widgetsSchema),
  'twenty-footer': optional(widgetsSchema),
  'twenty-above-header': optional(widgetsSchema),
  'twenty-below-header': optional(widgetsSchema),
  'twenty-between-posts': optional(widgetsSchema),
  'twenty-scripts': optional(widgetsSchema),
  'twenty-above-comments': optional(widgetsSchema),
  'twenty-below-comments': optional(widgetsSchema),
  'twenty-after-excerpt': optional(widgetsSchema),
  'twenty-post-dynamic': optional(widgetsSchema),
  'twenty-amp-after-first-paragraph': optional(widgetsSchema),
  'twenty-inside-post': optional(widgetsSchema),
  'twentry-after-third-paragrapth': optional(widgetsSchema),

  'health-sidebar': optional(widgetsSchema),
  'health-main-page': optional(widgetsSchema),
  'health-above-menu': optional(widgetsSchema),
  'health-below-menu': optional(widgetsSchema),
  'health-after-the-first-paragraph': optional(widgetsSchema),
  'health-after-thitd-paragraph': optional(widgetsSchema),
  'health-after-the-eighth-paragraph': optional(widgetsSchema),
  'health-post-dynamic': optional(widgetsSchema),
  'health-above-comments': optional(widgetsSchema),
  'health-below-comments': optional(widgetsSchema),
  'health-footer': optional(widgetsSchema),
  'health-scripts': optional(widgetsSchema),
};
