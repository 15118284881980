// @ts-ignore
import { array, boolean, number, object, string } from 'valibot';
import { SeoIronMaidenSchema, imageSchema } from '../generalSchemas';

export const recipe = object({
  postId: number(),
  images: array(imageSchema),
  title: string(),
  link: string(),
  totalTime: number(),
  activeTime: number(),
  commentsEnabled: boolean(),
});

export const fetchRecipesSchema = object({
  title: string(),
  totalPage: number(),
  currentPage: number(),
  totalRecipes: number(),
  recipes: array(recipe),
  isUserLoggedIn: boolean(),
  page: string(),
  taxonomies: object({
    cuisines: number(),
    dishTypes: number(),
    cookingMethods: number(),
    ingredients: number(),
    keywords: number(),
  }),
  seo: SeoIronMaidenSchema,
});
